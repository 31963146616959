<!--

  Created: Mon Feb 19 dhersey
  Desc: Used for cuisine page navigation organization + clarity
  props: 
    :isActive - String for active nav item

-->

<template>
  <!-- DESKTOP NAV -->
  <div class="cuisine-navigation">
    <!-- Change navArray to sortedArray -->
    <div v-for="item in navArray" v-bind:key="item" class="cnav-item" v-bind:class="item" @click="handleRedirect(item)">
      <div v-if="props.isActive == item" class="active"></div>
      <h1 class="v3-main-header" style="font-size: 32px; white-space: pre-wrap; text-align: center;">
        {{ labeller(item) }}
      </h1>
    </div>
  </div>
  <!-- Mobile Nav, active moved to top -->
  <div class="cuisine-navigation is-mobile">
    <div v-for="item, count in sortedArray" v-bind:key="item" class="cnav-item" v-bind:class="item" @click="handleRedirect(item)">
      <div v-if="count == 0" class="active"></div><!-- First item will always be "active" in mobile -->
      <h1 class="v3-main-header" style="font-size: 32px; text-align: center;">
        {{ labeller(item) }}
      </h1>
    </div>
    
  </div>

</template>
  
<script setup>
const props = defineProps({
  isActive: {
    type: String,
    default: "restaurants"
  },
});

let navArray = ["on-the-water", "experiences", "provisions", "restaurants"];
let navArrayClone = [...navArray] 
let first = props.isActive;
let sortedArray = navArrayClone.sort(function(x,y){ return x == first ? -1 : y == first ? 1 : 0;});

function handleRedirect(location){
  window.location.href = "/cuisine/" + location;
}

function labeller(item){
  switch (item) {
    case 'restaurants':
      return 'YACHT-FRIENDLY RESTAURANTS'
    case 'on-the-water':
      return 'ON-THE-WATER MENUS'
    case 'provisions':
      return "GALLEY\nPROVISIONS"
    case 'experiences':
      return 'FOOD\nEXPERIENCES'
    default:
      return 'ERROR';
  }
}

</script>

<style scoped lang="scss">
@import '../../../assets/stylesheets/colors.scss';

.cuisine-navigation{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3em auto 5em auto;
  @media only screen and (max-width: 1280px){
    display: none;
    &.is-mobile{
      display: flex;
      flex-direction: column;
    }
  }
  @media only screen and (min-width: 1281px){
    &.is-mobile{
      display: none;
    }
  }

  .cnav-item{
    width: 25%;
    height: 150px;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover{
      filter: brightness(85%);
    }
    @media only screen and (max-width: 1280px){
      width: 95%
    }
      .v3-main-header{
        color: inherit;
        text-transform: uppercase;
      }
      .active{
        position: absolute;
        width: 0;
        height: 0;
        margin-bottom: 220px;
        border: 50px solid transparent;
        border-top: 0;
        border-bottom: 70px solid $v3-french-blue;
      }
      &.restaurants{
        background-color: $v3-french-blue;
        color: $white-cap;
        .active{
          border-bottom: 70px solid $v3-french-blue;
        }
      }
      &.on-the-water{
        background-color: $v3-gold;
        color: $v3-navy-blue;
        .active{
          border-bottom: 70px solid $v3-gold;
        }
      }
      &.experiences{
        background-color: $v3-navy-blue;
        color: $v3-gold;
        .active{
          border-bottom: 70px solid $v3-navy-blue;
        }
      }
      &.provisions{
        background-color: $shallow;
        color: $white-cap;
        .active{
          border-bottom: 70px solid $shallow;
        }
      }
  }
}

.v3-main-header{
  text-align: center !important;
}

</style>