<!--

  Author: dhersey,
  Date: 7/28/22
  Desc:
    -Designed for listing job postings on the career page
    -All Data taken from ./job-listings.json
    -Two Data models (One of the two original listings is a split job posting (2 positions))
      1.) {
            "id",
            "job_title",
            "intro",
            "full_desc",
            "linkedin_url"
            "responsibilities":[]
            "requirements":[]
          }

      2.) {
            "id",
            "job_title",
            "intro",
            "full_desc",
            "linkedin_url",
            "responsibilities":[
                "name",
                "primary_responsibilities":[]
              ],
            "looking_for":[
              "name",
              "requirements":[]
            ]
          }


-->

<template>
  <div class="bg-text-block">
    <h3>{{ job_listings[x].job_title }}</h3>
    <div class="intro">
      {{ job_listings[x].intro }}
    </div>
    <div class="v-small-gutter"></div>
    <div class="bg-button full" id="open-modal" @click="showModal()">
      <div class="bg-button-label">SHOW MORE INFO</div>
      <div class="bg-button-icon"></div>
    </div>
  </div>

<!-- MODAL HEADER -->
  <div class="modal" :style="modalStyleHandler" id="modal-root">
    <div class="modal-content">
      <div class="modal-header">
        <span id="close-modal" @click="closeModal()">&times;</span>
        <h3 style="margin: 1em auto;">{{ job_listings[x].job_title }}</h3>
        <em>Barton &amp; Gray Mariners Club</em>
      </div>
      <div class="modal-body">
        <div class="modal-subtext-header">
          The Role
        </div>
<!-- DESC -->
        <p>{{ job_listings[x].full_desc }}</p>

<!-- RESPONSIBILIITES -->
        <!-- SPLIT RESPONSIBILITIES (TWO POSITIONS IN 1 JOB POSTING) DATA MODEL 2 -->
        <div class="modal-responsibilities" v-if="job_listings[x].responsibilities[0].name">
          <!-- 1 -->
          <div class="modal-subtext-header">
            <span>{{ job_listings[x].responsibilities[0].name }}</span> Responsibilities
          </div>
          <ul>
            <li v-for="responsibility in job_listings[x].responsibilities[0].primary_responsibilities" v-bind:key="responsibility">{{ responsibility }}</li>
          </ul>

          <!-- 2 -->
          <div class="modal-subtext-header">
            <span>{{ job_listings[x].responsibilities[1].name }}</span> Responsibilities
          </div>
          <ul>
            <li v-for="responsibility in job_listings[x].responsibilities[1].primary_responsibilities" v-bind:key="responsibility">{{ responsibility }}</li>
          </ul>
        </div>

        <!-- NORMAL RESPONSIBILITIES SECTION DATA MODEL 1 -->
        <div class="modal-responsibilities" v-else-if="job_listings[x].responsibilities">
          <div class="modal-subtext-header" v-if="job_listings[x].responsibility_header">
            {{ job_listings[x].responsibility_header }}
          </div>
          <div class="modal-subtext-header" v-else>
            Responsibilities
          </div>
          <ul>
            <li v-for="responsibility in job_listings[x].responsibilities" v-bind:key="responsibility">{{ responsibility }}</li>
          </ul>
        </div>

<!-- LOOKING FOR -->
        <!-- SPLIT LOOKING FOR (TWO POSITIONS IN 1 JOB POSTING) DATA MODEL 2-->
        <div class="modal-looking_for" v-if="job_listings[x].looking_for">
          <!-- 1 -->
          <div class="modal-subtext-header">
            What we are looking for in a <span>{{ job_listings[x].looking_for[0].name }}</span>
          </div>
          <ul>
            <li v-for="requirement in job_listings[x].looking_for[0].requirements" v-bind:key="requirement">{{ requirement }}</li>
          </ul>
          
          <!-- 2 -->
          <div class="modal-subtext-header">
            What we are looking for in a <span>{{ job_listings[x].looking_for[1].name }}</span>
          </div>
          <ul>
            <li v-for="requirement in job_listings[x].looking_for[1].requirements" v-bind:key="requirement">{{ requirement }}</li>
          </ul>
          
        </div>

<!-- REQUIREMENTS -->
        <div class="modal-requirements" v-if="job_listings[x].requirements">
          <div class="modal-subtext-header" v-if="job_listings[x].requirements_header">
            {{ job_listings[x].requirements_header }}
          </div>
          <div class="modal-subtext-header" v-else>
            Requirements
          </div>
          <ul>
            <li v-for="requirement in job_listings[x].requirements" v-bind:key="requirement">{{ requirement }}</li>
          </ul>
          
        </div>
        <!--PHYSICAL REQUIREMENTS-->
        <div class="modal-requirements" v-if="job_listings[x].physical_requirements">
          <div class="modal-subtext-header">
            Physical Requirements
          </div>
          <ul>
            <li v-for="requirement in job_listings[x].physical_requirements" v-bind:key="requirement">{{ requirement }}</li>
          </ul>
          
        </div>

<!-- BENEFITS -->
        <div class="modal-requirements" v-if="job_listings[x].benefits">
          <div class="modal-subtext-header">
            Barton &amp; Gray Mariners Club Benefits
          </div>
          <ul>
            <li v-for="benefit in job_listings[x].benefits" v-bind:key="benefit">{{ benefit }}</li>
          </ul>
        </div>

<!-- QUALIFICATIONS -->
        <div class="modal-requirements" v-if="job_listings[x].qualifications">
          <div class="modal-subtext-header" v-if="job_listings[x].qualifications_header">
            {{ job_listings[x].qualifications_header }}
          </div>
          <div class="modal-subtext-header" v-else>
            Qualifications
          </div>
          <ul>
            <li v-for="qualification in job_listings[x].qualifications" v-bind:key="qualification">{{ qualification }}</li>
          </ul>
        </div>
        

<!-- BUTTONS -->
        <div class="modal-buttons">
          <a :href="job_listings[x].linkedin_url">
            <div class="bg-button full" id="open-modal">
              <div class="bg-button-label">APPLY ON LINKEDIN</div>
              <div class="bg-button-icon"></div>
            </div>
          </a>
          <div class="bg-button full" id="open-modal" @click="closeModal()">
            <div class="bg-button-label">CLOSE</div>
            <div class="bg-button-icon"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import jobData from './job-listings.json'
export default {
  data() {
    return {
      job_listings: jobData,
      x: this.id,
      isModalVisible: false,
      isDebug: false,
    }
  },
  props:{
    job_title:{
      type: String,
      required: false
    },
    id:{
      type: Number,
      required: true
    }
  },
  methods: {
    showModal(){
      this.isModalVisible = true;
      if(this.isDebug){
        console.log('opening');
        console.log(this.isModalVisible);
      }
    },
    closeModal(){
      this.isModalVisible = false;
      if(this.isDebug){
        console.log('closing');
        console.log(this.isModalVisible);
      }
    }
  },
  computed:{
    modalStyleHandler: function(){
      var mdisplay = 'none'
      if(this.isModalVisible){
        mdisplay = 'block'
      }
      let styles = {
        display: mdisplay,
      }
      return styles;
    }
  },
  mounted() {
    if(this.isDebug){
      console.log("Job Listings JSON: ", this.job_listings);
      console.log("x: ", this.x);
      console.log("isModalVisible: ", this.isModalVisible);
    }
  },
}
</script>

<style scoped lang="scss">
@use 'stylesheets-bridge/www2/breaks';
@use 'stylesheets-bridge/www2/definitions';
@use 'stylesheets-bridge/animations';
@use 'stylesheets-bridge/colors';

a{
  max-width: 100%;
}

p{
  margin-top: 0;
  padding-left: 1em;
}

ul{
  margin-top: 0;
  margin-bottom: 2em;
  @media only screen and (max-width: 390px){
    padding-inline-start: 1em;
  }
}

h3{
  font-weight: 600;
}

.modal-header{
  font-weight: 500;
}

.bg-button{
  cursor: pointer;
}
.intro{
  margin-top:1em;
}

/* Open modal button styling */
#open-modal {
  
}

/* Modal styling */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10000;
  overflow: scroll;
}

.modal-content {
  margin: 5em auto;
  max-width: 60%;
  background-color: #fff;
  padding: 1em;
  animation: scale 0.5s ease-in-out;
  border-radius: 10px;
  box-shadow: 6.7px 6.7px 5.3px rgba(0, 0, 0, 0.02),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.03),
    100px 100px 80px rgba(0, 0, 0, 0.05);
  @include breaks.bg-breakpoint('narrow'){
    max-width: 90%;
  }
}

.modal-body {
  margin-top: 1em;
}

#close-modal {
  float: right;
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
}

.modal-buttons{
  display: inline-block;
  .bg-button{
    margin: 0.5em 1em;
  }
}

.modal-subtext-header{
  color: #388bca;
  font-weight: 500;
  text-transform: uppercase;
}

</style>