<!--

  Created: 01/31/2024 by dhersey
  Desc: New fleet project gallery

  Notes:
    - Very similar to BgV3Gallery
    - When updating image size, make sure to adjust the 
      translateX on slideOutRight and slideOutleft

-->

<template>
  <div>
    <div class="carousel-outer-wrapper" v-bind:class="{active: isSlideshowActive}">
      <div class="carousel-inner-wrapper carousel-holder">

          <div class="prev-button">
            <img class="hover-pointer" @click="changeSlide(-1)" src="https://cloud.bartonandgray.com/library/ux/icons/V3/gallery-arrow-prev.png">
          </div>

          <div class="prev-image" 
            @click="changeSlide(-1)"
            :key="slide_index"
            :style="{'background-image': nextPrevImageHandler(slide_index - 1)}">
          </div>

        <Transition :name="transitionHandler('slideOut')">
          <div class="image-slide" :key="slide_index" :style="{'background-image': 'url('+ props.gallery[slide_index] +')'}">

          </div>
        </Transition>

          <div class="next-image" 
            @click="changeSlide(1)"
            :key="slide_index"
            :style="{'background-image': nextPrevImageHandler(slide_index + 1)}"
            >
          </div>

          <div class="next-button">
            <img class="hover-pointer" @click="changeSlide(1)" src="https://cloud.bartonandgray.com/library/ux/icons/V3/gallery-arrow-next.png">
          </div>


      </div>
      
      <!-- BUTTON -->
      <div @click="toggleSlideshow()" class="v3-button-mini shadow hover-pointer" style="margin-top: 2em;">
        <img src="https://cloud.bartonandgray.com/library/brand/burgee-pole.svg">
        <div class="v3-button-mini-label">CLOSE</div>
      </div>
    </div>

    <!-- UNOPENED GALLERY -->
    <div class="gallery-wrapper">
      <img class="desktop-only" v-for="image, count in gallery" v-bind:key="image" :src="image" @click="toggleSlideshow(count)">
      <img class="mobile-only" v-for="image in gallery" v-bind:key="image" :src="image">
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted } from "vue";

  const props = defineProps({
    gallery: Array,
    image_gap:{
      type: String,
      default: "7px"
    },
  });

  const debugMode = ref(false);

  const isSlideshowActive = ref(false);
  let slide_index = ref(0);
  let direction = ref('Left');

  function nextPrevImageHandler(index){
    if (index < 0) {
      return 'url(' + props.gallery[props.gallery.length - 1] + ')';
    } else if (index >= props.gallery.length){
      return 'url(' + props.gallery[0] + ')';
    } else {
      return 'url(' + props.gallery[index] + ')';
    }
  }

  //changes slide_index value and direction
  function changeSlide(x){
    if (x > 0) {
      direction.value = 'Right';
    } else {
      direction.value = 'Left';
    }

    slide_index.value += x;
    
    testSlideIndex();
  }

  // Key controls
  if (isSlideshowActive.value) {
    window.addEventListener('keyup', handleKeypress);
  } else {
    window.removeEventListener('keyup', handleKeypress);
  }

  function handleKeypress(e) {
    switch (e.keyCode) {
      case 39: // right
        changeSlide(1)
        break;
      case 37: // left
        changeSlide(-1)
        break;
      case 27: // esc
        toggleSlideshow();
        break;
      default:
        break;
    }
  }

  function testSlideIndex(){
    if(slide_index.value > props.gallery.length -1){
      slide_index.value = 0;
    } else if (slide_index.value < 0){
      slide_index.value = props.gallery.length - 1;
    }
  }


  // if clicking on next (POSITIVE MOVEMENT), use slideout LEFT
  // if clicking on prev (NEGATIVE MOVEMENT), use slideout RIGHT
  function transitionHandler(animation_name) {
    if (direction.value == 'Right') {
      return animation_name + 'Left';
    } else {
      return animation_name + 'Right';
    }


  }

  function toggleSlideshow(x){
    slide_index.value = x;
    isSlideshowActive.value = !isSlideshowActive.value;
  }


  onMounted(() => {
    if(debugMode.value){
      console.log('gallery.length: ', props.gallery.length);
      console.log('gallery.length - 1: ', props.gallery.length - 1);
      console.log('slideOut'+direction.value);
      for (let index = 0; index < props.gallery.length; index++) {
        console.log(props.gallery[index], index);
      }
    }
  });
</script>

<style lang="scss" scoped>
  @import '../../../../assets/stylesheets/animations.css.scss';

  .gallery-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    max-width: 1440px;
    margin: 0 auto;
    margin-bottom: 200px;
    gap: v-bind(image_gap);
    img{
      width: 20%;
      height: auto;
      transition: 0.2s ease-in-out;
      &:hover{
        filter: brightness(90%);
        cursor: pointer;
      }
    }
  }

  .carousel-outer-wrapper{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: 1;
    
    &.active{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .carousel-inner-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1440px;
    gap: 5px;
    transition: 0.5s ease-in-out;
  }

  .prev-button, .next-button{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      max-width: 50px;
    }
  }

  .image-slide{
    min-width: 375px;
    min-height: 562.5px;
    border-radius: 15px;
    z-index: 10 !important;
    transition: 1s ease-in-out;
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .prev-image, .next-image{
    min-width: 312.5px;
    min-height: 562.5px;
    border-radius: 15px;
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.15s ease-in;
    &:hover{
      filter: brightness(85%);
      cursor: pointer;
    }
  }

  .prev-image, .next-image{
    // transform: translateZ(-250px)
    transform: scale(0.8);
    z-index: 3 !important;
    opacity: 0.8;
  }

  @keyframes slideFadeIn{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }

  .next-image{
    transform: translateX(-100px) scale(0.8);
    animation: slideFadeIn 0.5s ease-in-out;
  }

  .prev-image{
    transform: translateX(100px) scale(0.8);
    animation: slideFadeIn 0.5s ease-in-out;
  }



  //RIGHT enter || FINE
  .slideOutRight-enter-from{
    //opacity: 0;
    transform: translateX(-220px) scale(0.8);
    //@at-rootborder: none;
  }

  // FINE
  .slideOutRight-enter-to{
    //opacity: 1;
    transform: translateX(0) scale(1);
    //border: 5px solid blue;
  }

  //RIGHT leave
  .slideOutRight-leave-from{
    position: absolute;
    transform: translateX(0) scale(1);
    opacity: 1;
    //border: none;
  }

  .slideOutRight-leave-to{
    position: absolute;
    transform: translateX(155px) scale(0.8);
    opacity: 0;
    z-index: 1;
    //border: 5px solid red;
  }

  //LEFT enter
  .slideOutLeft-enter-from{
    //opacity: 0;
    transform: translateX(220px) scale(0.8);
    //border: none;
  }

  .slideOutLeft-enter-to{
    //opacity: 1;
    transform: translateX(0) scale(1);
    //border: 5px solid green;
  }

  //LEFT leave
  .slideOutLeft-leave-from{
    position: absolute;
    transform: translateX(0) scale(1);
    opacity: 1;
    //border: none;
    z-index: 1;
  }

  .slideOutLeft-leave-to{
    position: absolute;
    transform: translateX(-150px) scale(0.8);
    opacity: 0;
    z-index: 1;
    //border: 5px solid yellow;
  }

  .slideOutLeft-leave-active, .slideOutRight-leave-active,
  .slideOutRight-enter-active, .slideOutLeft-enter-active{
    transition: 0.5s ease-in;
  }


</style>