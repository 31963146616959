<template>
  <div>
    <p>Hi this is inside the component</p>
    <p class="special">And this too</p>
  </div>
</template>
<script>
  
export default {
    data() {
      return {
        greeting: 'Hello'
      }
    },
    components: {
    }
}
</script>
<style scoped>
p {
  color: yellow;
}
.special {
  color: red;
}
</style>

