<!--

  VueJS component that handles chart creation
  Req Imports:
    Chart.js

  Props:
    :graph_type
      type: String
      Required
      -Type of Chart.
      -Ex. 'bar', 'line', 'doughnut'
      -All types see:  https://www.chartjs.org/docs/latest/charts/area.html

    :import_data
      type: Object
      -Refers to data object
      Required
      -Contains chart dataset

    :import_options
      type: Object
      -Refers to option object
      Not Required
      -Additional Options... See https://www.chartjs.org/docs/latest/general/options.html

    :identifier
      type: String
      -Refers to canvas ID
      -Ex. 'myBarChart'

  SAMPLE DATA IN spec/data/chartJS/sample-data/
-->
<template>
  <div class="chart-wrapper">
    <canvas :id="id" ></canvas>
    <button v-on:click="debugTest()">debug</button>
  </div>
</template>

<style>
  .chart-wrapper{
    background-color: white;
    border-radius: .5em;
    padding: 1em;
    
    
  }
</style>

<script>


  export default {
    data() {
      return {
        isDebug: true,
        id: this.identifier,
        height: this.height,
        width: this.width,
        chart: {
          type: this.graph_type,
          data: this.import_data,
          options: {}
        },
        data2: {
          labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          datasets: [{
            label: 'My First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
          }]
        }
      }
    },
    props:{
      graph_type:{
        type: String,
        required: true
      },
      import_data:{
        type: Object,
        required: true
      },
      import_options:{
        type: Object,
        required: false,
        default: {}
      },
      identifier:{
        type: String,
        default: "myChart"
      },
      height: {
        type: Number,
        default: 200
      },
      width: {
        type: Number,
        default: 400
      }
    },
    computed:{
      createChart: function(){
        let ctx = document.getElementById(this.id).getContext('2d');
        let config = {
          type: this.chart.type,
          data: this.chart.data,
          options: this.chart.options
        };
        // Creates the chart
        let chartName = this.id;
        let savedName = chartName;
        chartName = new Chart(ctx, config);
        //this.debugTest();
      }
    },
    methods:{
      debugTest(){
        console.log('-------------------');
        console.log("ID: ", this.id);
        console.log("Type: ", this.chart.type);
        console.log("Data: ", this.chart.data);
        console.log("Options: ", this.chart.options);
        console.log('Config:', this.config);
        console.log('chartName:', this.savedName);
        console.log('import_data: ', this.import_data);
        console.log('-------------------');
      },
    },
    components:{

    },
    beforeMount(){

    },
    mounted(){
      this.createChart;
      if(this.isDebug){
        this.debugTest();
      }
    }
  }
</script>