<!--

  Author: dhersey
  Date: 11/28/22
  Desc: Used to create a simple table from json format
  Currently set up for catamaran scheduling but can be refactored to 
    grab different data easily

  Props:
    -"region": used to select certain data in the schedule data file

-->

<template>
  <table class="schedule-table" style="background-color: white;">
    <thead>
      <tr>
        <th v-for="(header, index) in selectSchedule.col_headers" v-bind:key="header" class="schedule-theader">{{selectSchedule.col_headers[index]}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(week, index) in selectSchedule.dates" v-bind:key="week">
        <th class="schedule-trow" :style="bgRowStyleHandler(week)">
          <a v-if="selectSchedule.mailto" :href="selectSchedule.mailto +' '+ selectSchedule.location_shorthand +' Inquiry ' +selectSchedule.dates[index].date">
            {{ selectSchedule.dates[index].date }}
          </a>
          <div v-else>
            {{ selectSchedule.dates[index].date }}
          </div>
        </th>
        <th class="schedule-trow" :style="bgRowStyleHandler(week)">
          {{ selectSchedule.dates[index].price }}
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import AndromedaSchedule from './andromeda-calendar-2022.json';
export default {
  data() {
    return {
      schedule_location: this.region,
      selectSchedule: AndromedaSchedule[0]
    }
  },
  props:{
    region: {
      type: String,
      required: true
    }
  },
  methods: {
    selectRegion(){
      var x;
      switch(this.schedule_location){
        case 'abacos':
          x = 0;
          break;
        case 'long-island':
          x = 1;
          break;
        case 'maine':
          x = 2;
          break;
        default:
          x = 0;
          break;
      }
      this.selectSchedule = AndromedaSchedule[x];
    },
    bgRowStyleHandler(weekObj){
      var color = '';
      var fweight = 300;
      switch(weekObj.color){
        case 'light':
          color = 'rgba(78, 211, 220, 0.11)';
          break;
        case 'dark':
          color = 'rgba(78, 152, 220, 0.2)';
          break;
        case 'reserved':
          color = 'rgba(254, 212, 63, 0.5)';
          break;
        default:
          color = 'rgba(78, 211, 220, 0.11)';
          break;
      }
      if(weekObj.isEvent){
        fweight = 600;
      }
      return {
        backgroundColor: color,
        fontWeight: fweight
      }
    }
  },
  mounted() {
    this.selectRegion();
  },
}
</script>

<style lang="scss" scoped>
@use 'stylesheets-bridge/www2/breaks';
@use 'stylesheets-bridge/www2/definitions';
@use 'stylesheets-bridge/animations';
@use 'stylesheets-bridge/colors';
</style>